import React from 'react';
import ContactUs from '../components/sections/ContactUs';

const Contact = () => {
  return (
    <>
      <ContactUs className="illustration-section-01" />
    </>
  );
}

export default Contact;