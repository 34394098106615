import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
//import FeaturesTiles from '../components/sections/FeaturesTiles';
//      <FeaturesTiles />
//import FeaturesSplit from '../components/sections/FeaturesSplit';
//<FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
//import Testimonial from '../components/sections/Testimonial';
//import Cta from '../components/sections/Cta';
//       <Cta split />

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
    </>
  );
}

export default Home;