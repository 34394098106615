import React, { useState } from 'react';
import classNames from 'classnames';
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
const FORM_ENDPOINT = "https://api.nprogramming.hr/mail";

const Contact = () => {
  const outerClasses = classNames('hero section center-content');
  const innerClasses = classNames('hero-inner section-inner');

  const [status, setStatus] = useState();
  const handleSubmit = (e) => {
    e.preventDefault();

    // Anything you need to inject dynamically
    const injectedData = {
      DYNAMIC_DATA_EXAMPLE: 123,
    };
    const inputs = e.target.elements;
    const data = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    Object.assign(data, injectedData);

    fetch(FORM_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        // It's likely a spam/bot request, so bypass it to validate via captcha
        if (response.status === 422) {
          Object.keys(injectedData).forEach((key) => {
            const el = document.createElement("input");
            el.type = "hidden";
            el.name = key;
            el.value = injectedData[key];

            e.target.appendChild(el);
          });

          e.target.submit();
          throw new Error("Please finish the captcha challenge");
        }

        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then(() => setStatus("Sa zadovoljstvom ćemo Vam odgovoriti u najkraćem mogućem roku."))
      .catch((err) => setStatus("Nažalost nešto je pošlo po zlu i nismo u mogućnosti primiti Vašu poruku."));
  };

  if (status) {
    return (
      <section className={outerClasses}>
        <div className="container-sm">
          <div className={innerClasses}>
            <div className="contact-us-content">
              <div className="text-2xl">Hvala Vam na javljanju!</div>
              <div className="text-md">{status}</div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="contact-us-content reveal-from-bottom" data-reveal-delay="200">
            <h2 className="mt-0 mb-16">
              <span className="text-color-primary">Kontaktirajte nas</span>
            </h2>
            <div className="container-xs">
              <form
                action={FORM_ENDPOINT}
                onSubmit={handleSubmit}
                method="POST">
                <div className="mb-16">
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Ime"
                      name="name"
                      className="form-input"
                      required
                    />
                  </div>
                </div>
                <div className="mb-16">
                  <div className="form-group">
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      className="form-input"
                      required
                    />
                  </div>
                </div>

                <div className="mb-16">
                  <div className="form-group">
                  <textarea
                    placeholder="Poruka"
                    name="message"
                    className="form-input"
                    rows="8"
                    required/>
                  </div>
                </div>

                <div className="mb-16">
                  <div className="form-group">
                    <ButtonGroup>
                      <Button color="primary" wideMobile type="submit">Pošalji</Button>
                    </ButtonGroup>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}


export default Contact;